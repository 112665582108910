export interface MonthData {
    [key: string]: number,

    total: number
}

export interface YearData {
    year: string;
    "1": MonthData;
    "10": MonthData;
    "11": MonthData;
    "12": MonthData;
    "2": MonthData;
    "3": MonthData;
    "4": MonthData;
    "5": MonthData;
    "6": MonthData;
    "7": MonthData;
    "8": MonthData;
    "9": MonthData;
}

export class RevenueYear {
    private data: YearData;
    private readonly months: MonthData[];
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    total: number;

    //TODO: implement the month array with a reducer. -jg
    constructor(data: YearData) {
        this.data = data;


        this.months = [
            data["1"] || {total: 0},
            data["2"] || {total: 0},
            data["3"] || {total: 0},
            data["4"] || {total: 0},
            data["5"] || {total: 0},
            data["6"] || {total: 0},
            data["7"] || {total: 0},
            data["8"] || {total: 0},
            data["9"] || {total: 0},
            data["10"] || {total: 0},
            data["11"] || {total: 0},
            data["12"] || {total: 0}
        ]

        this.months.forEach(currentMonth => {
            currentMonth.total = this.totalMonth(currentMonth);
        });

        this.q1 = this.months.slice(0, 3).reduce((previousValue, currentMonth) => previousValue + currentMonth.total, 0);
        this.q2 = this.months.slice(3, 6).reduce((previousValue, currentMonth) => previousValue + currentMonth.total, 0);
        this.q3 = this.months.slice(6, 9).reduce((previousValue, currentMonth) => previousValue + currentMonth.total, 0);
        this.q4 = this.months.slice(9).reduce((previousValue, currentMonth) => previousValue + currentMonth.total, 0);
        this.total = this.q1 + this.q2 + this.q3 + this.q4;
    }

    private totalMonth(monthData: MonthData): number {
        return Object.entries(monthData).reduce((previousValue, currentValue) => {
            let amount = 0;
            if (currentValue[0] !== 'total') {
                amount = currentValue[1];
            }
            return previousValue + amount;
        }, 0);
    }

    /**
     * Function exists to encapsulate zero-indexed month array. Not thrilled about this solution. -jg
     * @param month
     */
    getDataForMonth(month: number) {
        return this.months[month - 1];
    }
}
