import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import {ReviewComparison, ReviewComparisonProps} from "./RevenueComparison";
import React from "react";
import numeral from 'numeral';

interface ComparisonGroupProps {
    title: string
    comparisons: ReviewComparisonProps[];
}

export function ComparisonGroup({title,comparisons,children} : React.PropsWithChildren<ComparisonGroupProps>) {

    return <ListGroup.Item>
        <h4>{title}</h4>
        <Table size="sm" borderless responsive>
            <thead>
            <tr>
                <th/>
                <th/>
                <th/>
            </tr>
            </thead>
            <tbody>
            {comparisons.map((comparison, index) => {
                return (
                    <ReviewComparison title={comparison.title}
                                      value={comparison.value}
                                      priorAmount={comparison.priorAmount}
                                      key={index}
                    />
                );
            })}

            </tbody>
        </Table>
        {children}
    </ListGroup.Item>;
}
