import numeral from "numeral";
import React from "react";

export interface ReviewComparisonProps {
    title: string,
    value: number,
    priorAmount: number
}

export function ReviewComparison({priorAmount, title, value}: ReviewComparisonProps) {

    let percentDiff = (value / priorAmount) - 1;

    return <>
        <tr>
            <td>{title}</td>
            <td>{numeral(value).format("$0,0.00")}</td>
            <td className={`${percentDiff < 0 ? 'text-danger' : 'text-success'} text-right`}>
                {numeral(percentDiff).format("0.00%")}
            </td>
        </tr>
        <tr>
            <td/>
            <td className="text-muted">{numeral(priorAmount).format("$0,0.00")}</td>
            <td/>
        </tr>
    </>;
}
