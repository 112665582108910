import * as firebase from "firebase/app";
import 'firebase/auth';
import React, {useCallback, useState} from "react";


export interface CurrentUserContext{
    user: firebase.User | null;
    setCurrentUser: (currentUser: firebase.User | null) => void;
}

export default React.createContext<firebase.User | null>(null);


export const useUser = ():CurrentUserContext => {

    const [user, setUser] = useState<firebase.User | null>(null);

    const setCurrentUser = useCallback((currentUser: firebase.User | null) => {
        setUser(currentUser);
    },[] );

    return {
        user, setCurrentUser
    }
}
