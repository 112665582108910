import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from "./components/app";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.min.css'
import 'suneditor/dist/css/suneditor.min.css';
import {FirebaseContext} from "./components/Firebase";
import {Firebase} from "./components/Firebase/firebase";

ReactDOM.render(
    <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()} >
            <App/>
        </FirebaseContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
