import React, {useContext, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Bar, Doughnut} from "react-chartjs-2";
import {FirebaseContext} from "../Firebase";
import {Company} from "../Firebase/firebase";
import numeral from "numeral";
import EtherealColor from "ethereal-color";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import {map, reduce, tap} from "rxjs/operators";
import Button from "react-bootstrap/Button";
import {CompanyRevenueReport} from "./companyRevenueReport";
import {RevenueYear, YearData} from "../Firebase/RevenueYear";

//TODO: move this somewhere smarter -jg
export interface TooltipItem {
    // Label for the tooltip
    label: string,

    // Value for the tooltip
    value: string,

    // Index of the dataset the item comes from
    datasetIndex: number,

    // Index of this data item in the dataset
    index: number,

    // X position of matching point
    x: number,

    // Y position of matching point
    y: number
}

export const ReportView = () => {

    const firebase = useContext(FirebaseContext);

    const {Color, Palette, Gradient} = EtherealColor;

    const startColor = Color({r: 179, g: 51, b: 55});

    function createYearOverYearData(yearData: YearData[]) {
        const labels = yearData.map(year => year.year);
        const data = yearData.map(year => {
            return new RevenueYear(year).total;
        });

        return {
            labels: labels,
            datasets: [{label: 'Annual RevenueXX', backgroundColor: startColor.get('rgb').string, data: data}]
        };
    }

    const [yearOverYearData, setYearOverYearData] = useState<{ labels: string[], datasets: { label: string, data: number[] }[] }>()
    const [companies, setCompanies] = useState<Company[]>([]);
    const [yearData, setYearData] = useState<YearData[]>([]);
    const [selectedYear, setSelectedYear] = useState<string | undefined>(undefined);
    const [companyYearData, setCompanyYearData] = useState<{ labels: string[], datasets: { data?: number[], backgroundColor: string[] }[] }>({
        labels: [],
        datasets: []
    });
    const [pieTitle, setPieTitle] = useState<string>('');


    useEffect(() => {
        if (!selectedYear) return;

        const companySubscription = firebase?.getCompaniesForYear(selectedYear).pipe(map(value => {
            return value.sort((a, b) => a.total - b.total)
        })).subscribe(companies => {

            const labels = companies.map(company => company.name);
            const data = companies.map(company => {
                return company.total;
            });

            const palette = Palette();
            palette.random();

            const gradient = Gradient(palette, {count: labels.length}).toStringArray("rgb");
            const backgroundColors: string[] = [];
            Array.from(Array(companies.length)).forEach((value: number, index) => {
                backgroundColors.push(gradient[index]);
            })

            const year = yearData.find(value => value.year === selectedYear);

            if (year) {
                setPieTitle(`${selectedYear} Annual Revenue ${numeral(new RevenueYear(year).total).format('$0,0.00')}`)
            }

            setCompanyYearData({labels: labels, datasets: [{data: data, backgroundColor: backgroundColors}]});
        });

        return () => companySubscription?.unsubscribe();
    }, [selectedYear]);

    useEffect(() => {
        const unsubscribe = firebase?.getAllYearRevenueInfo().pipe(
            tap((yearData: YearData[]) => {
                return yearData.sort((a, b) => Number(a.year) - Number(b.year))
            })
        ).subscribe(value => {
            setYearOverYearData(createYearOverYearData(value));
            setYearData(value);
        })

        const unsubscribeCompanies = firebase?.getCompanies().pipe(tap(c => c.sort())).subscribe(companies => {
            setCompanies(companies);
        })

        return () => {
            unsubscribe?.unsubscribe();
            unsubscribeCompanies?.unsubscribe();
        };
    }, [])

    /*    useEffect(() => {
            firebase?.getRevenueItemsForCompany().subscribe({revItems => {

            }})
        })*/


    const chartOptions = {
        title: {
            display: true,
            text: 'Year over Year Revenue',
            position: 'top',
            fontSize: 18
        },
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                id: 'first-y-axis',
                type: 'linear',
                ticks: {
                    callback: (value: any, index: number, values: any[]) => {
                        return numeral(value).format('$0,0.00');
                    }
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: TooltipItem, data: any) => {
                    return numeral(tooltipItem.value).format('$0,0.00')
                }
            }
        }
    }

    const yearDetailOptions = {
        title: {
            text: pieTitle,
            display: true,
            fontSize: 18
        },
        legend: {display: false},
        tooltips: {
            callbacks: {
                label: (tooltipItem: TooltipItem, data: any) => {
                    const label = data.labels[tooltipItem.index];
                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return `${label}\n${numeral(value).format('$0,0.00')}`
                }
            }
        }
    }
    return (
        <Row>
            <Col>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle eventKey="0" as={Button} variant="link">
                                Year Over Year Revenue
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Bar
                                    data={yearOverYearData}
                                    type={'stacked'}
                                    options={chartOptions}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>

                        <Card.Header>
                            <Accordion.Toggle eventKey="1" as={Button} variant="link">
                                Revenue By Year
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">

                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Year</Form.Label>
                                                <Form.Control as="select" name="yearSelect"
                                                              value={selectedYear}
                                                              onChange={event => {
                                                                  setSelectedYear(event.target.value)
                                                              }}
                                                >
                                                    <option>Choose...</option>
                                                    {
                                                        yearData.map(year => {
                                                            return <option key={year.year}
                                                                           value={year.year}>{year.year}</option>
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col lg="9">
                                        {companyYearData && <Doughnut
                                            data={companyYearData}
                                            options={yearDetailOptions}
                                        />
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle eventKey="2" as={Button} variant="link">
                                Revenue By Company
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <CompanyRevenueReport companies={companies}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>
    )
}
