import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import {UserContext} from "../Firebase";
import Button from "react-bootstrap/Button";

interface AppNavbarProps {
    logoutHandler: () => void;
}

export default function AppNavbar({logoutHandler}:AppNavbarProps) {
    const user = useContext(UserContext);

    return <Navbar variant="dark" bg="dark" expand="lg" className="mb-2">
        <Navbar.Brand>
            <img
                src="/logo.png"
                alt="PWW Logo"
                width="30"
                height="30"
                className="d-inline-block align-top mr-3"
            />
            PWW Revenue
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-between">
            <Nav>
                <Nav.Link as={NavLink} to="/revenue">Revenue</Nav.Link>
                <Nav.Link as={NavLink} to="/companies">Companies</Nav.Link>
                <Nav.Link as={NavLink} to="/reports">Reports</Nav.Link>
            </Nav>
            <Button onClick={() => logoutHandler()}>Log out</Button>
        </Navbar.Collapse>
    </Navbar>;
}
