import * as yup from "yup";
import {Form as FormikForm, Formik} from "formik";
import Button from "react-bootstrap/Button";
import React from "react";
import {MyTextInput} from "../common";
import {FormikHelpers} from "formik/dist/types";

export interface CompanyFormValues {
    name: string;
    isActive: boolean;
}

export default function CompanyForm(props: { onSubmit: (values: CompanyFormValues, helper: FormikHelpers<CompanyFormValues>) => void }) {


    const validationSchema = yup.object({
        name: yup.string().required()
    });

    const formValues: CompanyFormValues = {
        name: '',
        isActive: true
    };


    return (
        <Formik
            initialValues={formValues}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}
        >
            <FormikForm>
                <MyTextInput autoFocus label="Company Name" name="name"/>
                <Button className="float-right" type="submit">Add</Button>
            </FormikForm>
        </Formik>
    )
}
