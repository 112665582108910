import {FieldHookConfig, useField} from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import React, {useEffect, useRef} from "react";

type MyTextInputProps = FieldHookConfig<string> & {
    label?: string,
    type?: string,

}
export const MyTextInput = ({autoFocus, label, type = 'text', ...props}: MyTextInputProps) => {
    const [field, meta] = useField(props);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus) {

            inputRef.current?.focus();
        }
    }, [inputRef,autoFocus])
    return (
        <FormGroup>
            {label ? <Form.Label htmlFor={props.name}>{label}</Form.Label> : null}
            <Form.Control type={type} {...field}
                          step="any"
                          ref={inputRef}
                          isInvalid={meta.touched && meta.error != null}/>
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
        </FormGroup>
    );
};
