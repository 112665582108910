import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CompanyForm,{CompanyFormValues} from "./CompanyForm";
import Table from 'react-bootstrap/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Card from 'react-bootstrap/Card';
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import Modal from 'react-bootstrap/Modal';
import {FirebaseContext} from "../Firebase";
import {FormikHelpers} from "formik/dist/types";
import {Company} from "../Firebase/firebase";


function CompanyRow({...props}) {
    return <tr>
        <td>{props.company.name}</td>
        <th>
            <ButtonGroup className="float-right">
                <Button type="button" size="sm">
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button type="button" size="sm" variant="danger">
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </ButtonGroup>
        </th>
    </tr>;
}

export const CompanyView = () => {

    const [showCompanyForm, setShowCompanyForm] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);

    const firebase = useContext(FirebaseContext);

    const handleSubmit = (values: CompanyFormValues, helper: FormikHelpers<CompanyFormValues>) => {

        firebase?.addCompany(values.name).then((result) => {
            helper.resetForm();
            setShowCompanyForm(false);
        });
    }

    useEffect(() => {
       let unsubscribe = firebase?.getCompanies().subscribe(companies => {
            setCompanies(companies);
        });

       return () => {
               unsubscribe?.unsubscribe();
           }
    },[firebase]);

    return (
        <>
            <Modal show={showCompanyForm} onHide={() => setShowCompanyForm(false)}>
                <Modal.Header closeButton>
                    <h3>Add New Company</h3>
                </Modal.Header>
                <Modal.Body>
                    <CompanyForm onSubmit={handleSubmit}/>
                </Modal.Body>
            </Modal>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="mb-2">

                                <h3 className="d-inline">Companies</h3>
                                <Button className="float-right" type="button" size="sm"  onClick={() => setShowCompanyForm(true)}>
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="ml-1">Add</span>
                                </Button>
                            </div>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    companies.map(value => {
                                        return <CompanyRow key={value.id} company={value} />
                                    })
                                }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
