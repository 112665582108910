import {Company} from "../Firebase/firebase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../Firebase";
import {tap} from "rxjs/operators";
import { Bar } from "react-chartjs-2";
import numeral from "numeral";
import { TooltipItem } from "./reportView";
import EtherealColor from "ethereal-color";

interface CompanyRevenueReportProps {
    companies: Company[]
}

interface BarChartData {
    labels: string[];
    datasets: { label?: string, backgroundColor?:string, data: number[] }[] }

export function CompanyRevenueReport({companies}: CompanyRevenueReportProps) {


    const firebase = useContext(FirebaseContext);
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
    const [chartData, setChartData] = useState<BarChartData>();

    const {Color} = EtherealColor;

    const backgroundColor = Color({r: 179, g: 51, b: 55});


    useEffect(() => {
        if(selectedCompanyId === ''){
            setChartData(undefined);
            return;
        }

        console.info('loading revenueItems')
        const companyYearTotalsSubscription = firebase?.getRevenueItemsForCompany(selectedCompanyId).pipe(
            tap(revItems => revItems.sort((p,c) => Number.parseInt(p.year) - Number.parseInt(c.year) )),
        ).subscribe(companyTotals =>{
            const data = companyTotals.reduce((acc, total) => {
                acc.labels.push(total.year);
                acc.datasets[0].data.push(total.total);
                const totalSpentByClient = acc.datasets[0].data.reduce((p, c) => p+c);
                acc.datasets[0].label = `${total.name}:  ${numeral(totalSpentByClient).format('$0,0.00')}`;
                return acc;
            },{labels:[], datasets:[{backgroundColor:backgroundColor.get("rgb").string, data:[]}]} as BarChartData )

            setChartData(data)});

        return () => companyYearTotalsSubscription?.unsubscribe();

    },[selectedCompanyId]);

    const chartOptions = {
        title: {
            display: true,
            text: 'Revenue By Company',
            position: 'top',
            fontSize: 18
        },
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                id: 'first-y-axis',
                type: 'linear',
                ticks: {
                    callback: (value: any, index: number, values: any[]) => {
                        return numeral(value).format('$0,0.00');
                    }
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: TooltipItem, data: any) => {
                    return numeral(tooltipItem.value).format('$0,0.00')
                }
            }
        }
    }

    return <Row>
        <Col lg={3}>
            <Form>
                <Form.Group>
                    <Form.Label>Company</Form.Label>
                    <Form.Control as="select"
                                  value={selectedCompanyId}
                                  onChange={event => {setSelectedCompanyId(event.target.value)}}
                    >
                        <option value={0}>Choose...</option>
                        {companies.map(company => <option key={company.id} value={company.id}>{company.name}</option>)}
                    </Form.Control>
                </Form.Group>
            </Form>
        </Col>
        <Col>
            {chartData && <Bar
                data={chartData}
                options={chartOptions}
            />}
        </Col>
    </Row>;
}
