import Modal from "react-bootstrap/Modal";
import React, {useContext, useEffect, useState} from "react";
import {Field, FieldHookConfig, Form as FormikForm, Formik, FormikHelpers, useField} from "formik";
import * as yup from "yup";
import {MyTextInput} from "../common";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {MyDropDown} from "../common/MyDropDown";
import {MyTextArea} from "../common/MyTextArea";
import Col from "react-bootstrap/Col";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {FirebaseContext} from "../Firebase";
import {Company, MonthNumber} from "../Firebase/firebase";
import parse from "date-fns/parse";
import format from "date-fns/format";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import {TestContext} from "yup";
import endOfMonth from "date-fns/endOfMonth";
import SunEditor from "suneditor-react";
import sunEditor from "suneditor";
import {text} from "@fortawesome/fontawesome-svg-core";

export interface EditRevenueItemData {
    id?: string;
    companyId: string;
    companyName?: string | null;
    amount: number;
    statusId: string;
    month: MonthNumber;
    year: number;
    repeat: boolean;
    repeatMonth: MonthNumber;
    repeatYear: number;
    percentComplete: number;
    description: string;
}

type MyAsyncTypeaheadProps = FieldHookConfig<string> & {
    label: string,
    loading: boolean,
    options: Company[],
    selected?: Company[],
    onSearch: (search: string) => void
}

function MyAsyncTypeahead({...props}: MyAsyncTypeaheadProps) {

    const [field, meta, helpers] = useField(props);

    let isInvalid = meta.touched && meta.error != null;

    return <FormGroup>
        <Form.Label htmlFor={props.name}>Company</Form.Label>
        <AsyncTypeahead
            id={props.name}
            labelKey="name"
            clearButton
            onChange={(selected: Company[]) => {
                const option = selected.length > 0 ? selected[0] : null;
                if (option?.id) {
                    helpers.setValue(option.id);
                } else {
                    helpers.setValue('');
                }
            }}
            onBlur={e => {
                helpers.setTouched(true);
            }}
            onSearch={query => props.onSearch(query)}
            isLoading={props.loading}
            options={props.options}
            defaultSelected={props.selected}
            placeholder="Search For Company..."
            isInvalid={isInvalid}
            multiple={false}

        />
        <div className="my_invalid_feedback" hidden={!isInvalid}>
            {meta.error}
        </div>
    </FormGroup>;
}

interface RevenueEditFormProps {
    editMode: 'new' | 'update';
    revenueData: EditRevenueItemData;
    show: boolean;
    onHide: () => void;
    handleSubmit: (values: EditRevenueItemData, helpers: FormikHelpers<EditRevenueItemData>) => void;
}

export default function RevenueEditForm({editMode,handleSubmit,onHide,revenueData,show}: RevenueEditFormProps) {

    const [isLoading, setIsLoading] = useState(false);
    const [companyOptions, setCompanyOptions] = useState<Company[]>([]);
    const firebase = useContext(FirebaseContext);
    const [monthOptions] = useState<{ value: number, label: string }[]>(generateMonthDropDown());
    const [yearOptions] = useState<{ value: number, label: string }[]>(generateYearDropDown());
    const [formTitle,setFormTitle] = useState<string>('');

    useEffect(() => {
        setFormTitle(editMode === 'new'? 'Add Revenue Item' : 'Edit Revenue Item' );
    },[editMode])

    function repeatDateValidator(this:TestContext ,value:any):boolean {
        const year = this.parent.year;
        const month = this.parent.month;

        const repeatMonth = this.parent.repeatMonth;
        const repeatYear = this.parent.repeatYear;

        const date = endOfMonth(parse(`${month}-${year}`, 'MM-yyyy', new Date()));
        const repeatDate = endOfMonth(parse(`${repeatMonth}-${repeatYear}`,'MM-yyyy',new Date()))

        return repeatDate.getTime() > date.getTime();
    }

    const validationSchema = yup.object().shape({
        id: yup.string(),
        companyId: yup.string().required('Company required'),
        amount: yup.number().required().moreThan(0),
        statusId: yup.string().required(),
        month: yup.number().required(),
        year: yup.number().required(),
        repeat: yup.boolean(),
        repeatMonth: yup.number().when('repeat',{is:true,then:yup.number().integer().required()
                .test('repeatDateValidator','Date must be at least one month ahead',repeatDateValidator)}),
        repeatYear: yup.number().when('repeat',{is:true,then:yup.number().integer().required()
                .test('repeatDateValidator','Date must be at least one month ahead',repeatDateValidator)}),
        percentComplete: yup.number().moreThan(-1).lessThan(101).integer(),
        description: yup.string().required(),
    });




    function searchCompanies(query: string) {
        setIsLoading(true);
        firebase?.searchCompanies(query).subscribe((companies) => {
                setCompanyOptions(companies);
                setIsLoading(false);
            }
        )

    }

    function generateMonthDropDown() {
        return new Array(12).fill(1, 0, 12).map((value, index) => {
            let monthInteger = (index + 1);
            const monthDate = parse(monthInteger.toString(), 'M', new Date());

            return {value: monthInteger, label: format(monthDate, 'MMMM')}
        });
    }

    function generateYearDropDown() {
        const today = new Date();
        const startYear = today.getFullYear() - 5;

        return new Array(10).fill(Math.random()).map((value, index) => {
            let year = (startYear + index);
            return {value: year, label: `${year}`}
        })

    }

    function getSelectedCompany() {

        let id = revenueData?.companyId ? revenueData?.companyId : '';

        let name = revenueData?.companyName? revenueData?.companyName : '';
        return [{id: id, name: name} as Company];
    }

    return <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                {formTitle}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Formik
                onSubmit={(values,helpers) => {
                    //Formik is always returning a string when it should return a number. blrrrf. -jg
                    if(typeof values.year === "string") {
                        console.info('year was a string');
                        values.year = Number.parseInt(values.year);
                    }
                    handleSubmit(values,helpers)}}
                initialValues={revenueData}
                validationSchema={validationSchema}
            >
                {props => {
                    return <FormikForm>
                    <Form.Row>
                        <Col>
                            <MyAsyncTypeahead name="companyId"
                                              loading={isLoading}
                                              onSearch={searchCompanies}
                                              options={companyOptions}
                                              label="Company"
                                              selected={getSelectedCompany()}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <MyTextInput name="amount" label="Amount" type="number"/>
                        </Col>
                        <Col>
                            <MyDropDown label="Status" name="statusId" options={
                                [{label: "Hot Lead",value:"3"}, {label: "Active",value:"12"}, {label: "Issue Invoice",value:"20"}, {label: "Invoiced",value:"2"}]
                            }/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <MyDropDown name="month" options={
                                monthOptions
                            } label="Month"/>

                        </Col>
                        <Col>
                            <MyDropDown name="year"
                                        label="Year"
                                        defaultValue={new Date().getFullYear()}
                                        options={yearOptions}/>

                        </Col>
                    </Form.Row>
                    <MyTextInput name="percentComplete" label="Percent Complete"/>
                        <Form.Group>
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <SunEditor
                                name="description"
                                setContents={props.values['description']}
                                onBlur={(event, editorContents) => {
                                    console.log('dag! I been called');
                                    const contentTestContainer = document.createElement('div');
                                    contentTestContainer.innerHTML = editorContents;
                                    let textContent = contentTestContainer.textContent || '';
                                    textContent = textContent.replace(/\s+/g,'');

                                    const fieldHelpers = props.getFieldHelpers('description');

                                    if (textContent.length > 0) {
                                        console.log('text context accepted. Setting value on formik api');
                                        fieldHelpers.setValue(editorContents);
                                    }
                                    else{
                                        fieldHelpers.setValue('');
                                    }

                                    props.getFieldHelpers('description').setTouched(true);
                                }}
                                showToolbar
                                height={250}
                                setOptions={
                                    {
                                        mode: 'classic',
                                        buttonList: [['undo', 'redo'],['outdent', 'indent', 'list', 'bold', 'align', 'horizontalRule', 'blockquote'], ['table', 'link', 'codeView']]
                                    }}
                            />
                            <div className="my_invalid_feedback" hidden={!props.getFieldMeta('description').touched && props.getFieldMeta('description').error != null}>
                                {props.getFieldMeta('description').error}
                            </div>
                        </Form.Group>
                    <div hidden={editMode === "update"}>

                    <FormGroup>
                        <Field name="repeat" as={Form.Check} label="Repeat"/>
                    </FormGroup>
                    <Collapse in={props.values.repeat}>
                        <Form.Row>
                            <Col>
                                <MyDropDown name="repeatMonth" options={
                                    monthOptions
                                } label="Month"/>
                            </Col>
                            <Col>
                                <MyDropDown name="repeatYear"
                                            label="Year"
                                            defaultValue={new Date().getFullYear()}
                                            options={yearOptions}/>
                            </Col>
                        </Form.Row>
                    </Collapse>
                    </div>
                    <MyTextInput type="hidden" name="id"/>
                    <Button type="submit" variant="primary" className="float-right">Save</Button>
                </FormikForm>
                }}
            </Formik>
        </Modal.Body>
    </Modal>;
}
