import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import React from "react";
import {RevenueItem} from "../Firebase/firebase";
import numeral from "numeral";
import ReactHtmlParser from "react-html-parser";
import RevenueStatusUtil, {RevenueStatus} from "./RevenueStatusUtil";

interface RevenueRowProps {
    revenueItem: RevenueItem
    handleEdit: (revenueItem: RevenueItem) => void
    handleDelete: (revenueItem: RevenueItem) => void
}
export default function RevenueRow({revenueItem, handleDelete, handleEdit}:RevenueRowProps) {

    return <tr>
        <td>{numeral(revenueItem.amount).format('$0,0.00')}</td>
        <td>{revenueItem.companyName}</td>
        <td>{revenueItem.percentComplete}%</td>
        <td>
            {ReactHtmlParser(revenueItem.description)}
        </td>
        <td>
            {RevenueStatusUtil.getStatusTitle(revenueItem.statusId)}
        </td>
        <td>
            <ButtonGroup size="sm">
                <Button onClick={() => handleEdit(revenueItem)}>
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
                <Button variant="danger" onClick={() => handleDelete(revenueItem)}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </ButtonGroup>
        </td>
    </tr>;
}
