import React from "react";
import Table from "react-bootstrap/Table";
import RevenueRow from "./RevenueRow";
import {RevenueItem} from "../Firebase/firebase";

interface RevenueTableProps{
    revenueItems: RevenueItem[],
    handleDelete: (revenueItem: RevenueItem) => void
    handleEdit: (revenueItem: RevenueItem) => void
}
export const RevenueTable = ({revenueItems, handleDelete, handleEdit}: RevenueTableProps) => (<Table striped hover responsive>
    <thead>
        <tr>
            <th>Amount</th>
            <th>Company</th>
            <th>%</th>
            <th>Description</th>
            <th>Status</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    {revenueItems.map(value => (
        <RevenueRow key={value.id} revenueItem={value} handleDelete={handleDelete} handleEdit={handleEdit} />
    ))}
    </tbody>
</Table>);
