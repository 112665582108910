import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../../App.css";
import RevenueView from "../revenue";
import {AppNavbar} from "../navigation";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import format from "date-fns/format";
import Col from "react-bootstrap/Col";
import {CompanyView} from "../companies/CompanyView";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {UserContext, useUser} from "../Firebase";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {ReportView} from "../reports/reportView";


function TheWholeApp() {

    const {setCurrentUser} = useUser();

    function logout() {
        firebase.auth().signOut().then(value => {
            setCurrentUser(null);
        });
    }

    return (
        <Router>
            <AppNavbar logoutHandler={logout}/>
            <Switch>
                <Route
                    exact
                    path="/revenue/:year(\d{4})/:month(0?[1-9]|1[0-2])"
                    component={RevenueView}
                />
                <Route exact path="/companies" component={CompanyView}/>
                <Route exact path="/reports" component={ReportView}/>
                <Route path="/">
                    <Redirect to={`/revenue/${format(new Date(), "yyyy/M")}`}/>
                </Route>
            </Switch>
        </Router>
    )
}

const App = () => {

    const {user, setCurrentUser} = useUser();
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user: firebase.User | null) => {

            setShowLoading(false);
            setCurrentUser(user);
        })
        return () => unsubscribe();
    }, [setCurrentUser])

    return (
        <UserContext.Provider value={user}>
        <Container fluid>
            {showLoading && <Row><Col lg="12" className="align-self-center" ><Spinner animation="border"></Spinner></Col></Row>}
            {user && <TheWholeApp/>}
            {!user && !showLoading && <Button onClick={() => {
                firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())
            }}>Log In</Button>}
        </Container>
        </UserContext.Provider>

    )
};

export default App;
