import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import {collectionData, docData} from "rxfire/firestore";
import endOfMonth from "date-fns/endOfMonth";
import parse from "date-fns/parse";
import addMonths from "date-fns/addMonths";
import {YearData} from "./RevenueYear";
import RevenueStatusUtil, { RevenueStatus } from "../revenue/RevenueStatusUtil";


const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

export interface Company {
    id?: string
    name: string
    isActive: boolean
}

export type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;


export interface RevenueItem {
    statusId: RevenueStatus;
    id?: string
    companyName: string | null
    companyId: string
    amount: number
    status?: string
    year: number,
    month: MonthNumber
    percentComplete: number
    description: string
    /**
     * has child revItems from a repeat action
     */
    hasChildren?: boolean;
}


interface CompanyYearTotal {
    year: string,
    total: number,
    id: number
    name: string
}

export class Firebase {
    auth: firebase.auth.Auth;
    private database: firebase.firestore.Firestore;



    constructor() {
        firebase.initializeApp(config);
        this.database = firebase.firestore();

        //this code is for local development and is removed by webpack on build -jg
        if (process.env.NODE_ENV === "development") {
            this.database.settings({
                host: "localhost:8080",
                ssl: false
            });
        }

        this.auth = firebase.auth();
        console.info('Firebase initialized');
    }

    doSignOut() {
        return this.auth.signOut();
    }

    addCompany(companyName: string) {
        return this.database.collection('companies').add({
            name: companyName,
            isActive: true,
            lowerCaseName: companyName.toLocaleLowerCase()
        })
    }

    getCompanies() {
        let companiesRef = this.database.collection('companies').orderBy("name", "asc");
        return collectionData<Company>(companiesRef, 'id');
    }

    getCompany(companyId: string | null) {
        return this.database.collection('companies').doc(`${companyId}`).get();
    }

    searchCompanies(query: string) {
        const lowerCaseQuery = query.toLowerCase();
        const companiesRef = this.database.collection('companies').orderBy("lowerCaseName", "asc")
            .startAt(lowerCaseQuery).endAt(lowerCaseQuery + "\uf8ff");
        return collectionData<Company>(companiesRef, 'id');
    }

    async addRevenueItemsWithRepeatChildren(parentItem: RevenueItem, toDate: Date) {

        try {
            const fromDate = endOfMonth(parse(`${parentItem.month}-${parentItem.year}`, 'MM-yyyy', new Date()));

            const parentRef = this.database.collection('revenueItems').doc();

            const company = await this.getCompany(parentItem.companyId);
            if(! company.exists) {
                throw new Error(`Did not find company with id ${parentItem.companyId}`);
            }
            parentItem.companyName = company.data()?.name;

            parentItem.status = RevenueStatusUtil.getStatusTitle(parentItem.statusId);
            parentItem.hasChildren = true;


            const batch = this.database.batch();

            batch.set(parentRef, parentItem);

            const parentId = parentRef.id;

            let dateIndex = addMonths(fromDate, 1);
            while (dateIndex.getTime() <= toDate.getTime()) {
                const childRevItem = {
                    ...parentItem, month: dateIndex.getMonth() + 1, year: dateIndex.getFullYear(),
                    parent: parentId, percentComplete: 0
                };
                batch.set(this.database.collection('revenueItems').doc(), childRevItem)
                dateIndex = addMonths(dateIndex, 1);
            }

            return batch.commit();
        } catch (e) {
            throw new Error(`Error creating revItem with repeating children: ${e}`)
        }
    }

    async addRevenueItem(item: RevenueItem) {

        //this is a stupid workaround to get the company name, even though the form
        // has the value. I can't figure out a simple way to include it, unfortunately. -jg
        const company = await this.getCompany(item.companyId);

        item.companyName = company.get('name');
        item.status = RevenueStatusUtil.getStatusTitle(item.statusId);
        const revenueCollectionRef = this.database.collection('revenueItems');

        return revenueCollectionRef.add(item);
    }

    getRevenueItemsFor(month: Date) {

        const year = month.getFullYear();
        const monthNumber = month.getMonth() + 1; // zero-indexed, so add 1 -jg

        const revenueRef = this.database.collection('revenueItems')
            .where('year', '==', year)
            .where('month', "==", monthNumber);

        return collectionData<RevenueItem>(revenueRef, 'id');
    }

    deleteRevenueItem(revenueItem: RevenueItem) {
        return this.database.collection('revenueItems').doc(revenueItem.id).delete()
    }

    async updateRevenueItem(itemToUpdate: RevenueItem) {

        //delete the 'id' field from the update to keep the datastore tidy - jg
        const revId = itemToUpdate.id;
        delete itemToUpdate.id;

        const company = await this.getCompany(itemToUpdate.companyId);

        if (!company.exists) throw new Error(`Did not find company with id ${itemToUpdate.companyId}`);

        itemToUpdate.companyName = company.data()?.name;
        itemToUpdate.status = RevenueStatusUtil.getStatusTitle(itemToUpdate.statusId);


        return this.database.collection('revenueItems')
            .doc(revId).update(itemToUpdate);
    }

    getRevenueInfoForYear(year: string) {
        const yearDocRef = this.database.collection('years').doc(year);
        return docData<YearData>(yearDocRef)
    }

    getAllYearRevenueInfo() {
        const yearsRef = this.database.collection('years');

        return collectionData<YearData>(yearsRef, 'year');
    }

    getCompaniesForYear(year: string) {
        const companyYearsRef = this.database.collection('years').doc(year).collection('yearCompanyTotals');

        return collectionData<{ total: number, name: string, companyId: string }>(companyYearsRef);
    }

    getRevenueItemsForCompany(companyId: string) {
        const companyRef = this.database.collectionGroup('yearCompanyTotals')
            .where('companyId', '==', companyId)

        return collectionData<CompanyYearTotal>(companyRef);
    }


}


