import ListGroup from "react-bootstrap/ListGroup";
import React, {useEffect, useState} from "react";
import {ComparisonGroup} from "./ComparisonGroup";
import {RevenueItem} from "../Firebase/firebase";
import {ReviewComparisonProps} from "./RevenueComparison";
import {RevenueYear} from "../Firebase/RevenueYear";
import numeral from "numeral";
import RevenueStatusUtil, {RevenueStatus} from "./RevenueStatusUtil";
import Table from "react-bootstrap/Table";

interface RevenueReviewProps {
    monthItems: RevenueItem[];
    currentYearData: RevenueYear | null;
    lastYearData: RevenueYear | null;
}
export default function RevenueReview({monthItems,currentYearData,lastYearData}: RevenueReviewProps) {


    function getLastYearMonthAmount() {
        let amount = 0;
        if (monthItems.length > 0) {
            const currentMonth = monthItems[0].month;

            const lastYearsMonthAmount = lastYearData?.getDataForMonth(currentMonth).total;
            amount = lastYearsMonthAmount? lastYearsMonthAmount : 0;
        }
        return amount;
    }

    const monthStatusTotals = monthItems.reduce((agg, current) => {

        const field = current.statusId === RevenueStatus.Invoiced ? "invoiced" : "toInvoice";
        agg[field] = agg[field] + current.amount;
        agg.total = agg.total+=current.amount;

        agg[current.statusId] = (agg[current.statusId] || 0) + current.amount;
        return agg;
    }, {invoiced:0, toInvoice:0, total:0} as {[key:string]:number})

    const [monthComparison, setMonthComparison] = useState<ReviewComparisonProps>(
        {title: 'Month', priorAmount: 0, value: 0});

    useEffect(() => {
        const monthTotal = monthItems.map(value => value.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        setMonthComparison({title: 'Month', priorAmount: getLastYearMonthAmount(), value: monthTotal})

    }, [monthItems]);

    let annualComparisons = [
        {title: 'Q1', value: currentYearData? currentYearData.q1 : 0, priorAmount: lastYearData? lastYearData.q1 : 0},
        {title: 'Q2', value: currentYearData? currentYearData.q2 : 0, priorAmount: lastYearData? lastYearData.q2 : 0},
        {title: 'Q3', value: currentYearData? currentYearData.q3 : 0, priorAmount: lastYearData? lastYearData.q3 : 0},
        {title: 'Q4', value: currentYearData? currentYearData.q4 : 0, priorAmount: lastYearData? lastYearData.q4 : 0},
        {title: 'Total', value: currentYearData? currentYearData.total : 0, priorAmount: lastYearData? lastYearData.total:0}
    ];


    let monthComparisons = [monthComparison];


    function formatNumber(amount: number) {
        return numeral(amount).format("$0,0.00");
    }

    return <div>
        <h3>Revenue in Review</h3>
        <ListGroup variant="flush">
            <ComparisonGroup title="Monthly Totals" comparisons={monthComparisons}>
                <div>
                    <Table id="status-summary" size="sm" borderless responsive>
                        <thead>
                        <tr>
                            <th> </th>
                            <th> </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{RevenueStatusUtil.getStatusTitle(RevenueStatus.Active)}</td>
                            <td className={'text-right'}>
                                {formatNumber( monthStatusTotals[RevenueStatus.Active])}
                            </td>
                        </tr>
                        <tr>
                            <td>{RevenueStatusUtil.getStatusTitle(RevenueStatus.HotLead)}</td>

                            <td className={'text-right'}>
                                {formatNumber(monthStatusTotals[RevenueStatus.HotLead])}
                            </td>
                        </tr>
                        <tr>
                            <td>{RevenueStatusUtil.getStatusTitle(RevenueStatus.IssueInvoice)}</td>

                            <td className={'text-right'}>
                                {formatNumber(monthStatusTotals[RevenueStatus.IssueInvoice])}
                            </td>
                        </tr>
                        <tr>
                            <td>{RevenueStatusUtil.getStatusTitle(RevenueStatus.Invoiced)}</td>
                            <td className={'text-right'}>
                                {formatNumber(monthStatusTotals[RevenueStatus.Invoiced])}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </ComparisonGroup>
            <ComparisonGroup title="Year in Review" comparisons={annualComparisons}/>
        </ListGroup>
    </div>;
}
