import {faPlusCircle} from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfMonth from "date-fns/startOfMonth";
import React, {useContext, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import Pagination from "react-bootstrap/Pagination";
import Row from "react-bootstrap/Row";
import {useHistory, useLocation, useParams} from "react-router-dom";
import RevenueEditForm, {EditRevenueItemData} from "./RevenueEditorForm";
import RevenueReview from "./RevenueReview";
import {RevenueTable} from "./RevenueTable";
import {FirebaseContext} from "../Firebase";
import {MonthNumber, RevenueItem} from "../Firebase/firebase";
import addMonths from "date-fns/addMonths";
import Spinner from "react-bootstrap/Spinner";
import {tap} from "rxjs/operators";
import endOfMonth from "date-fns/endOfMonth";
import {RevenueYear} from "../Firebase/RevenueYear";
import {RevenueStatus} from "./RevenueStatusUtil";

export default function RevenueView(props: any) {



    const firebase = useContext(FirebaseContext);
    const [showRevenueEditor, setShowRevenueEditor] = useState(false);
    const [revenueItems, setRevenueItems] = useState<RevenueItem[]>([]);
    const [revenueItemToEdit, setRevenueItemToEdit] = useState<EditRevenueItemData>(getEmptyEditRevenueItemData);
    const [editMode, setEditMode] = useState<'new'|'update'>('new');
    const [editorHandler, setEditorHandler] = useState();
    const [fetchingRevenueItems, setFetchingRevenueItems] = useState(true);
    const [currentYearData, setCurrentYearData] = useState<RevenueYear | null>(null);


    const [lastYearData, setLastYearData] = useState<RevenueYear | null>(null);
    const history = useHistory();


    const location = useLocation();

    const {year, month} = useParams();

    function parseDateFromPath() {
        return parse(`${year}-${month}`,
            'yyyy-M', startOfMonth(new Date()));
    }

    const [revenueMonth, setRevenueMonth] = useState(parseDateFromPath());

    useEffect(() => {
        setRevenueMonth(parseDateFromPath)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        const lastYearUnsubscribe = firebase?.getRevenueInfoForYear((parseInt(year) - 1).toString()).subscribe(value => {
            setLastYearData(new RevenueYear(value));
        })

        const currentYearUnsubscribe = firebase?.getRevenueInfoForYear(year).subscribe(value => {
            setCurrentYearData(new RevenueYear(value));
        })

        return () => {
            currentYearUnsubscribe?.unsubscribe();
            lastYearUnsubscribe?.unsubscribe()
        };
    }, [firebase, year])

    useEffect(() => {
        setFetchingRevenueItems(true);
        const unsubscribe = firebase?.getRevenueItemsFor(revenueMonth)
            .pipe(tap(revenueItems => {
                revenueItems.sort((p,c) => c.amount - p.amount)
            }))
            .subscribe((revenueItems: RevenueItem[]) => {
                setRevenueItems(revenueItems);
                setFetchingRevenueItems(false);
            });

        return () => {
            unsubscribe?.unsubscribe()
        }

    }, [firebase, revenueMonth])

    const saveRevenueItem = async (data: EditRevenueItemData) => {
        const revenueItem = {
            amount: data.amount,
            companyId: data.companyId,
            month: data.month,
            year: data.year,
            description: data.description,
            percentComplete: data.percentComplete,
            statusId: data.statusId
        } as RevenueItem;

        if (data.repeat) {
            const toDate = endOfMonth(parse(`${data.repeatMonth}-${data.repeatYear}`, 'MM-yyyy', new Date()));

            await firebase?.addRevenueItemsWithRepeatChildren(revenueItem, toDate);
        } else {
            await firebase?.addRevenueItem(revenueItem);
        }

        setShowRevenueEditor(false);
    }

    function moveToMonth(monthOffset: number) {
        let newMonth = addMonths(revenueMonth, monthOffset);
        history.push(`/revenue/${format(newMonth, 'yyyy/M')}`);
    }

    async function updateRevenueItem(data: EditRevenueItemData) {
        try {
            await firebase?.updateRevenueItem({
                amount: data.amount,
                year: data.year,
                month: data.month,
                companyId: data.companyId,
                companyName: data.companyName ? data.companyName : '',
                description: data.description,
                id: data.id,
                percentComplete: data.percentComplete ? data.percentComplete : 0,
                statusId: data.statusId as RevenueStatus
            })
            setShowRevenueEditor(false);
        } catch (e) {
            throw e;
        }
    }

    function editRevenueItem(revenueItem: RevenueItem) {
        setRevenueItemToEdit({
            amount: revenueItem.amount,
            companyId: revenueItem.companyId,
            companyName: revenueItem.companyName,
            description: revenueItem.description,
            id: revenueItem.id,
            month: revenueItem.month,
            year: revenueItem.year,
            percentComplete: revenueItem.percentComplete,
            repeat: false,
            repeatMonth: revenueItem.month,
            repeatYear:revenueItem.year,
            statusId:revenueItem.statusId
        });
        setEditorHandler(() => updateRevenueItem);
        setEditMode("update");
        setShowRevenueEditor(true);
    }

    function deleteRevenueItem(revenueItem: RevenueItem) {
        firebase?.deleteRevenueItem(revenueItem).then(value => {
        });
    }

    function addNewRevenueItem() {
        setEditMode("new");
        setEditorHandler(() => saveRevenueItem);
        setRevenueItemToEdit(getEmptyEditRevenueItemData());
        setShowRevenueEditor(true);
    }

    function getEmptyEditRevenueItemData():EditRevenueItemData{
        const today = new Date();
        const month = today.getMonth()+1 as MonthNumber;
        return {
            amount: 0,
            companyId: '',
            description: "",
            month: month,
            percentComplete: 0,
            repeat: false,
            repeatYear: today.getFullYear(),
            repeatMonth: month,
            statusId: '',
            year: today.getFullYear()
        };
    }


    return <Row>
        <RevenueEditForm handleSubmit={editorHandler} show={showRevenueEditor}
                         revenueData={revenueItemToEdit}
                         onHide={() => setShowRevenueEditor(false)} editMode={editMode}/>
        <Col md={12} lg={9}>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <Navbar expand="sm">
                            <Navbar.Text>
                                <h3 className="d-inline">Revenue for {format(revenueMonth, 'LLLL, yyyy')}</h3>
                            </Navbar.Text>
                            <Navbar.Toggle/>
                            <Navbar.Collapse className="justify-content-end">
                                <Form inline>
                                    <Button size="sm" className="mr-2" onClick={() => addNewRevenueItem()}>
                                        <FontAwesomeIcon icon={faPlusCircle}/>
                                        <span className="ml-1">Add</span>
                                    </Button>
                                    <Pagination className="revenue_pagination">
                                            <span onClick={event => moveToMonth(-12)}>
                                                <Pagination.First/>
                                            </span>
                                        <span onClick={event => moveToMonth(-1)}>
                                        <Pagination.Prev/>
                                        </span>
                                        <span onClick={event => moveToMonth(1)}>
                                        <Pagination.Next/>
                                        </span>
                                        <span onClick={event => moveToMonth(12)}>
                                            <Pagination.Last/>
                                        </span>
                                    </Pagination>
                                </Form>
                            </Navbar.Collapse>
                        </Navbar>
                    </Card.Title>

                    {fetchingRevenueItems ? <Spinner animation="border"/> : <RevenueTable revenueItems={revenueItems}
                                                                                          handleEdit={editRevenueItem}
                                                                                          handleDelete={deleteRevenueItem}/>}

                </Card.Body>
                <Card.Footer>
                    <ButtonGroup className="float-right">
                        <Button onClick={() => addNewRevenueItem()}>
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            <span className="ml-1">Add Revenue</span>
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </Col>
        <Col lg={3}>
            <RevenueReview monthItems={revenueItems} currentYearData={currentYearData} lastYearData={lastYearData}/>
        </Col>
    </Row>
        ;
}
