import {useField} from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import React from "react";

export function MyDropDown(props: { name: string, options: { label?: string, value: any }[], label: string, defaultValue?: any }) {
    const [field, meta] = useField(props);

    return <FormGroup>
        <Form.Label htmlFor={props.name}>{props.label}</Form.Label>
        <Form.Control as="select" {...field} defaultValue={props.defaultValue}
                      isInvalid={meta.touched && meta.error != null}>
            <option key="xxx" value="">Choose...</option>
            {props.options.map(value => <option key={value.value}
                                                value={value.value}>{value.label ? value.label : value.value}</option>)}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
            {meta.error}
        </Form.Control.Feedback>
    </FormGroup>;
}
